.Footer {
  border-top: 2px solid #000;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #ffffffaa;
  backdrop-filter: blur(5px);
}

.Address {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  font-size: 20px;
  padding: 10px;
  text-align: center;
  width: 100%;
}

/* contact link formatting */
.Address a {
  display: flex;
  align-items: center;
  padding: 10px;
  color: black;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.4s ease-in-out,
              color 0.2s  ease-in-out;
}

/* highlight links is contact section */
.Address a:hover, .Address a:focus {
  background: var(--primary);
  color: white;
}

/* invert icons to be more visible with darker background */
.Address a:hover:not(:first-child) .Icon, .Address a:focus:not(:first-child) .Icon {
  filter: invert(1);
}

/* icon formatting */
.Icon {
  width: 1.5em;
  height: 1.5em;
  transition: all 0.2s ease-in-out;
}

@media screen and (min-width: 666px) {
  .Icon {
    width: 2em;
    height: 2em;
  }
  .Address a {
    font-size: 1.25rem;
  }
}