form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  width: 100%;
}

input, textarea {
  width: 100%;
  margin: 0.5rem 0;
  padding: 0.75rem;
  border-radius: 0.4rem;
}

.Message {
  margin: 0 2rem;
  font-size: 1.25rem;
  font-weight: 600;
}

.Footer {
    width: 100%;
}

.Statement {
	font-size: 1.5rem;
	line-height: 1.1;
	margin-bottom: 0.5rem;
	text-align: center;
}

@media screen and (min-width: 576px) {
  form {
    width: 90%;
  }
}

@media screen and (min-width: 1025px) { 
  form {
    width: 80%;
  }
}

@media screen and (min-width: 1400px) { 
  form {
    width: 70%;
  }
}