:root {
  /* custom colors */
  --primary: #0b3142ff;
  --middle-grey: #515867ff;
  --light-grey: #a0a9b1ff;
  --highlight-50: #08abc4dd;
  --dark-grey: #2a303cff;
  --light: #eef9fbff;
  --middle-blue: #7bd2e0ff;
  --white: #fff;
  --black: #000;
  --shadow: #111;

  /* other variables */
  --nav-item: 36px;
  --title-margin: 20px;
  --logo-size: 70px;
  --footer-height: 82px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  line-height: 1;
  font-family: Arial, Helvetica, sans-serif;
  background: url("./images/bellevue-skyline.webp") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  box-shadow: inset 0 0 15px var(--shadow);
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

input, select, option, optgroup, textarea, button,
pre, code {
  font-size: 100%;
  font-family: inherit;
}

ol, ul {
  list-style: none;
}

.Button {
  background-color: var(--dark-grey);
  border-radius: 0.25em;
  border: 2px solid var(--light);
  color: white;
  cursor: pointer;
  font-size: 1.25rem;
  margin: 0.5rem;
  padding: 0.5em 1em;
  text-align: center;
  text-decoration: none;
}

.Button:hover {
  color: black;
  background-color: var(--light-grey);
  border-color: var(--middle-grey);
}

.Main {
  padding-top: 175px;
  display: flex;
  flex-direction: column;
  transition: height 0.5s;
  padding-bottom: 120px;
}

/* Welcome message */
.Banner {
  margin: 1rem 0;
  font-size: 25px;
  text-align: center;
  background-color: var(--highlight-50);
  padding: 20px;
  border: var(--black) solid 2px;
  font-weight: 700;
}

.Page {
  display: grid;
  background-color: #000000dd;
  margin: 0;
  padding: 2rem;
  line-height: 1.2rem;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.fade-exit-active, .fade-enter-active{
  transition: opacity 400ms ease-in-out;
}

@media screen and (min-width: 576px) {
  .Banner {
      margin: 1rem 2rem;
  }
}

@media screen and (min-width: 576px) {
  .Page {
      margin: 0 2rem;
  }
}