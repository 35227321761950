.Subheader {
	border-bottom: 2px solid white;
	font-size: 2rem;
	line-height: 2.25rem;
	margin: 1rem 0;
	padding-bottom: 0.5rem;
	padding-inline: 1rem;
	width: fit-content;
}

.Group {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.GroupTitle {
	border-bottom: 2px solid white;
	font-size: 1.5rem;
	margin: 1rem auto;
	min-width: 100%;
	padding-bottom: 0.75rem;
	text-align: center;
}

.ResumeList {
	display: flex;
	flex-wrap: wrap;
	font-size: 1.25rem;
	line-height: 1.5rem;
	margin-left: 2rem;
}

.ResumeList > li {
	padding: 1rem;
	text-align: center;
	flex: 1 1 0%;
}

.Page, .PageCol {
	align-self: flex-start;
	color: white;
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
	line-height: 1.2rem;
}

.PageCol {
	flex-direction: column;
	align-items: center;
}

.Header, .ActivityTitle {
	color: white;
	text-align: center;
	font-size: 1.5rem;
	font-weight: 700;
	border-bottom: 2px solid white;
	width: 100%;
	padding-bottom: 0.5rem;
	margin-bottom: 1rem;
	line-height: 1.5rem;
}

.ActivityTitle {
	margin-top: 1rem;
	margin-inline: auto;
	max-width: 900px;
}

.Info {
	display: inline;
}

.Icon {
	height: 3rem;
}

.Container {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}

.Img {
	width: 40vw;
	height: 40vw;
	max-width: 400px;
	max-height: 400px;
	border: var(--primary) solid 4px;
	box-shadow: 5px 5px 15px var(--shadow);
	margin-bottom: 2rem;
	margin-right: 1rem;
}

.Note {
	font-size: 1rem;
	color: var(--light-grey);
	margin-left: 0.5rem;
}

@media screen and (min-width: 1300px) { 
	.Header {
		order: -1;
		font-size: 2rem;
		margin: 2rem 0;
	}

	.Info {
		width: calc(50vw - 2rem);
		padding-left: 2rem;
		font-size: 1.25rem;
		line-height: 1.5rem;
	}

	.Page {
		align-items: flex-start;
	}
}